import React from "react"
import { Helmet } from "react-helmet-async"
import Layout from "./layout"
import EmailSignUp from "./EmailSignUp"
import HymnListItem from "./HymnListItem"
import slugUtil from "../../slug-util"

export default (props) => {
  const authors = props.pageContext.authors;

  function getItemModel(author) {
    return { href: `/authors/${slugUtil.createSlugForPerson(author)}`, title: author  }
  }

  const listItems = authors.map(meter => {
      const model = getItemModel(meter);
      return <HymnListItem key={model.href} model={model} />
    });

  return (
    <Layout>
      <Helmet>
        <title>Authors</title>
      </Helmet>
      <div id="home-page-hymn-lists" className="container">
        <div className="item">
          <h1>Authors</h1>
          <ul>
            {listItems}
          </ul>
        </div>
      </div>

      <div style={{marginTop: `3em`}}>
        <EmailSignUp />
      </div>
    </Layout>
  )
}
